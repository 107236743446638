<template>
  <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
    <v-timeline-item
        v-for="(item, i) in items"
        :key="i"
        :color="item.color"
        :icon="'mdi-numeric-' + (i + 1)"
        icon-color="black"
        fill-dot
    >
      <component
          :is="item.componente"
          :color="item.color"
          :processo="processoSelecionado"
          :historico="historicosAcaoProcesso"
          @feito="recuperarHistorico"
      />
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import {mapState} from "vuex";
import historicoAcaoProcessoService from "@/services/historicoAcaoProcesso.service";

export default {
  name: "Acoes",

  data() {
    return {
      historicosAcaoProcesso: {}
    };
  },

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"]),

    items() {
      let items = [];

      if (this.processoSelecionado.tipoProcesso.usaNotaEnem) {
        items.push({
          color: "blue lighten-3",
          componente: () =>
              import(
                  "@/components/pages/admin/processos/acoes/UploadNotasEnemItem"
                  )
        });
      }

      if(this.processoSelecionado.possuiAlocacao){
        items.push({
          color: "red lighten-3",
          componente: () =>
              import(
                  "@/components/pages/admin/processos/acoes/AlocacaoItem"
                  )
        });
      }

      if (this.processoSelecionado.tipoProcesso.possuiAprovacao) {
        items.push({
          color: "green lighten-3",
          componente: () =>
              import(
                  "@/components/pages/admin/processos/acoes/AprovacaoItem"
                  )
        });
      }

      if(this.processoSelecionado.tipoProcesso.possuiClassificacao) {
        items.push({
          color: "amber lighten-3",
          componente: () =>
              import("@/components/pages/admin/processos/acoes/ClassificacaoItem")
        });
      }

      return items;
    }
  },

  async created() {
    let loader = this.$loading.show();

    await this.recuperarHistorico();

    loader.hide();
  },

  methods: {
    async recuperarHistorico() {
      await historicoAcaoProcessoService
          .recuperarHistoricosAcaoProcesso(this.processoSelecionado.oid)
          .then(response => {
            this.historicosAcaoProcesso = response.data;
          })
          .catch(() => {
          });
    }
  }
};
</script>
